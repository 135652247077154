import styled from 'styled-components';
import vars from '../lib/styled';

export default styled.div`
    background: ${vars.muted};
    margin-top: ${props => (props.offset ? '-200px' : '0')};
    padding-top: ${props => (props.offset ? 'calc(200px + 1rem)' : '1px')};
    padding-bottom: 4rem;
    position: relative;
`;
