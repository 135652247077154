import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

const SEO = ({ title, description }) => (
    <Helmet>
        <title>{title} | Marcangelo Foods</title>
        {description && (
            <meta
                name="description"
                content={description.description}
            />
        )}
    </Helmet>
);

SEO.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
        description: PropTypes.string
    })
};

SEO.defaultProps = {
    description: {
        description: ''
    }
};

export default SEO;
