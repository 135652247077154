import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import vars from '../lib/styled';

const LabelText = styled.span`
    font-size: 0.699em;
    font-weight: 800;
    position: absolute;
    bottom: 1rem;
    text-transform: uppercase;
    transition-duration: 500ms;
    transition-property: bottom, color;
`;

const LabelWrapper = styled.div`
    padding: 0 0.25rem;
    width: ${(props) => (props.full ? '100%' : '50%')};

    @media (max-width: ${vars.breakpoint.tablet}) {
        width: 100%;
    }
`;

const Required = styled.span`
    color: darkred;
    margin-left: 0.15rem;
    font-weight: bold;
`;

const Label = styled.label`
    background-color: ${vars.muted};
    border: 2px solid ${vars.mutedText};
    box-sizing: border-box;
    display: block;
    margin-bottom: 0.5rem;
    transition-duration: 500ms;
    transition-property: background-color, box-shadow;
    padding: 1.25rem 0.5rem 0.25rem 2rem;
    position: relative;

    &:focus-within {
        box-shadow: 2px 3px 12px rgba(215, 215, 215, 0.5);
        background-color: ${vars.contrast};
        border-color: ${vars.accent};

        ${LabelText} {
            bottom: calc(100% - 1.75rem);
            color: ${vars.accent};
        }
    }

    input:not([value=''])
        ~ ${LabelText},
        select:not([value=''])
        ~ ${LabelText},
        textarea:not([value=''])
        ~ ${LabelText} {
        bottom: calc(100% - 1.75rem);
    }
`;

const Input = ({ title, required, full, ...rest }) => {
    const inputRef = useRef(null);

    return (
        <LabelWrapper full={full} onClick={() => inputRef.current.focus()}>
            <Label htmlFor={rest.name}>
                <Field
                    id={rest.name}
                    innerRef={inputRef}
                    required={required}
                    autoComplete="off"
                    {...rest}
                />
                <LabelText>
                    {title}
                    {required && <Required>*</Required>}
                </LabelText>
            </Label>
        </LabelWrapper>
    );
};

Input.propTypes = {
    title: PropTypes.string.isRequired,
    required: PropTypes.bool,
    full: PropTypes.bool.isRequired,
};

Input.defaultProps = {
    required: false,
};

export default Input;
