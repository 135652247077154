import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import vars from '../lib/styled';

export const StandardButton = css`
    background-color: ${(props) =>
        props.primary ? vars.primary : 'transparent'};
    border: 2px solid
        ${(props) => (props.primary ? vars.contrast : vars.accent)};
    border-radius: 25px;
    color: ${(props) => (props.primary ? vars.contrast : vars.secondary)};
    cursor: pointer;
    display: inline-block;
    font-size: 0.833em;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: 3;
    margin: 0 auto;
    padding: 0 1.5rem;
    text-decoration: none;
    transition-duration: 500ms;
    transition-property: background-color, color;
    transition-timing-function: ease-in;
    white-space: nowrap;

    &:focus,
    &:hover {
        background-color: ${(props) =>
            props.primary ? vars.primaryHover : vars.accent};
        color: ${vars.contrast};
    }

    @media (max-width: ${vars.breakpoint.phone}) {
        font-size: 0.75em;
        padding: 0 0.75rem;
    }
`;

export const ButtonLinkExternal = styled.a.attrs({ target: '_blank' })`
    ${StandardButton}
`;
export const ButtonLinkToService = styled.a.attrs({ target: '_blank' })`
    ${StandardButton}
    font-size: 12px;
    border: none;

    @media (max-width: ${vars.breakpoint.laptop}) {
        border: 2px solid
            ${(props) => (props.primary ? vars.contrast : vars.accent)};
    }
`;
export const ButtonLink = styled(Link)`
    ${StandardButton}
`;
export default styled.button`
    ${StandardButton}
`;

export const ShareIcon = styled.a`
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    margin: 1rem 0.25rem 0;
    height: 2rem;
    padding: 0.25rem;
    text-align: center;
    width: 2rem;

    svg {
        height: 80%;
        margin-top: 5%;
        width: 80%;

        path {
            fill: #fff;
        }
    }
`;

export const ButtonIcon = styled.button`
    background: transparent;
    border: 0;
    color: ${vars.primaryHover};
    cursor: pointer;
    display: block;
    font-size: 2em;
    position: absolute;
    top: calc(-50px - 1em);
    left: ${(props) => (props.right ? '100%' : 'auto')};
    right: ${(props) => (props.right ? 'auto' : '100%')};
    text-align: center;
    transition: color 500ms;

    &:hover,
    &:focus {
        color: ${vars.primary};
    }

    @media (max-width: ${vars.breakpoint.laptop}) {
        left: ${(props) => (props.right ? 'auto' : '-2rem')};
        right: ${(props) => (props.right ? '-2rem' : 'auto')};
    }

    @media (max-width: ${vars.breakpoint.tablet}) {
        display: none;
    }
`;
